import React, {useState, useEffect, useContext} from 'react';
import './LoginHeader.css';
import logo from '../../images/frugal_logo_with_name.png';
import {Navigate} from "react-router-dom";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {AuthContext} from "../../context/authContext";
import {UserContext} from "../../context/userContext";
const settings = require("../../utils/settings");
import { makeStyles } from '@mui/styles';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   loginErrors: {
      color:theme.palette.error.main,
   },
   loginErrorContainer: {
      display: "flex",
      alignItems: "center",
   }

}));

export default function LoginHeader(props) {
   const classes = useStyles();

   // Auth state
   const { setAuthToken, isLoggedIn, setIsLoggedIn }  = useContext(AuthContext);
   const { setUser, setUserLoading} = useContext(UserContext);
   // Form state
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [loginError, setLoginError] = useState('');

   // Change handlers
   const handleEmailChange = e => setEmail(e.target.value);
   const handlePasswordChange = e => setPassword(e.target.value);

   useEffect(() => {
      const listener = event => {
         if (event.code === "Enter" || event.code === "NumpadEnter") {
            login();
         }
      };
      document.addEventListener("keydown", listener);
      return () => {
         document.removeEventListener("keydown", listener);
      };
   }, [email, password]);


   if (isLoggedIn) {
      return <Navigate to="/dashboard"/>;
   }

   return (
      <Grid container className={"login-header"}>
         <Grid container className={"login-bar"} alignItems={"center"}>
            <Grid item xs={12} md={4} className={"logo"}>
               <Grid container justifyContent={"flex-start"}>
                  <img src={logo} alt="app logo"/>
               </Grid>
            </Grid>

            <Grid item xs={12} md={8} className={"login"}>
                  <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                     <Grid item xs={12} md={2} className={classes.loginErrorContainer}>
                        <Typography className={classes.loginErrors}>{loginError}</Typography>
                     </Grid>
                     <Grid item xs={12} sm={6} md={4} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                        <TextField
                           id="email"
                           label="Email"
                           variant="filled"
                           onChange={handleEmailChange}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} md={4} style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "10px", paddingTop: "10px"}}>
                        <TextField
                           id="password"
                           label="Password"
                           type="password"
                           variant="filled"
                           onChange={handlePasswordChange}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} md={2}>
                        <Grid container sx={{display:'flex', justifyContent:{xs: 'flex-end', md: 'flex-start'}, alignItems:'center'}}>
                           <Grid item>
                              <Button onClick={login} variant="contained" color="secondary" style={{ marginRight: 20, marginTop: 10, marginBottom: 12} }>
                                 Login
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
            </Grid>

         </Grid>
         <Grid container className={"style-hr2"} justifyContent={"flex-end"} alignContent={"center"}>
            <Grid item>
               <Button  variant="contained" color="primary" href="/register" style={{ margin: 12}}>
                  Register
               </Button>
            </Grid>
         </Grid>
      </Grid>
   );

   function login() {
      let url = new URL(settings.appConfig.authHost + '/login');
      let reqBody = JSON.stringify({"email": email, "password": password});
      let reqHeaders = {"Content-Type": "application/json"};
      fetch(url, {
         method: 'POST',
         headers: reqHeaders,
         body: reqBody
      }).then((response) => {
         if (response.status === 200) {
            return response.json();
         } else {
            throw 'Invalid email or password';
         }
      }).then((body) => {
         setAuthToken(body.token);
         addUserInfo(body.token);
      }).then(() => {
         setIsLoggedIn(true);
      }).catch((error) => {
         console.error(error);
         setLoginError(error);
      });
   }

   function addUserInfo (token) {
      const encoded = token.split(".")[1];
      const decoded = JSON.parse(atob(encoded));
      const parsedUser = {
         userId: decoded.sub,
         fname: decoded.fName,
         role: decoded.role,
         accountId: decoded.accountId
      }

      // Persist to cookie
      let userString = btoa(JSON.stringify(parsedUser));
      Cookies.set("user", userString);
      Cookies.set("token", token);
      // Set in userContext
      setUser(parsedUser);
      setUserLoading(false);
   }
}
